@import "../../../styles/base/theme";

%overflow-x-scroll {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
}

%content-padding {
  padding: 24px 12px;
}

.person-registrations {
  &-container {
    display: flex;
    height: calc(100% - 100px);
    overflow: hidden;
  }

  &-content {
    @extend %overflow-x-scroll;
    @extend %content-padding;

    flex: 1;
  }

  &-aside {
    @extend %overflow-x-scroll;
    @extend %content-padding;

    flex: 0 0 20%;

    // Enough width so that the "Registration" heading fits, and so that at least a significant portion of the name of
    // the selected registration in the Select menu can be seen.
    min-width: 240px;
  }

  &-aside--full-width {
    flex: 1;
  }

  &-sidebar {
    @extend %overflow-x-scroll;
    @extend %content-padding;

    flex: 0 0 20%;

    // Enough width so that the text in the "Submit Registration" button does not get truncated.
    min-width: 240px;
  }

  &-bottom {
    display: flex;
    margin: 30px 0;
  }

  &-not-submitted {
    border-radius: 4px;
    padding: 15px;
  }

  &-icon-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.menu-item-submission-status:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: -9px;
  height: 1px;
  background-color: $color-gray91;
}

.ant-alert-with-description.alert-registration-status {
  text-align: center;

  .ant-alert-message {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    margin-bottom: 0;
  }

  .person-registration-submission-status-alert__last-submitted-at {
    display: inline-block;
    padding-top: 10px;
    font-size: 0.8rem;
  }
}
