.form-field-form-item--non-input {
  .ant-form-item-children {
    display: block;
  }

  .ant-alert {
    // Add margin so that the alert lines up with the form label (and is equal in height) when in a horizontal layout.
    margin-top: 4px;
    margin-bottom: 3px;
  }
}

.form-field-form-item--read-only {
  label {
    font-weight: bold;
  }
}
