.my-registrations-list {
  &__content {
    padding-top: 12px;
  }

  &__left-sidebar {
    &-label {
      font-weight: bold;
      margin-bottom: 5px;
      white-space: nowrap;
    }
    margin-bottom: 22px;
  }
}