.text-editor {
  &-toolbar {
    margin-bottom: 8px;

    .ant-btn {
      &:not(.ant-btn-icon-only) {
        padding-right: 8px;
        padding-left: 8px;
      }

      &-group + .ant-btn-group {
        margin-left: 8px;
      }
    }
  }

  &-field {
    height: 150px !important;
    overflow-x: hidden;
    overflow-y: auto;
  }

  [data-slate-editor] > * + * {
    line-height: 1.5;
    margin-top: 16px;
  }

  blockquote {
    border-left: 2px solid #ddd;
    padding-left: 10px;
    font-style: italic;
    margin-right: 0;
    margin-left: 0;
    color: #aaa;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;

    &.is-focused {
      box-shadow: 0 0 0 2px rgba($primary-color, 0.4);
    }
  }
}
